@import '../../mixins';

.mobile-menu {
  --mm-color-background: var(--bg-white);
  --mm-color-border: rgba(255, 255, 255, 0.10);
  --mm-color-icon: var(--accent-dark-primary);
  --mm-color-text: var(--text-dark-primary);
  --mm-color-text-dimmed: var(--text-dark-primary);
  --mm-color-background-highlight: var(--bg-white);
  --mm-color-background-emphasis: transparent;
  --mm-color-focusring: var(--accent-dark-primary);
  --mm-navbar-size: #{rem(18)};

  top: rem(0);

  width: 100%;
  max-width: rem(490);
  height: 100vh;
  display: flex;

  @include mediaBigDesktop {
    max-width: big(490);
  }

  @include mediaLaptop {
    max-width: rem(400);
  }

  @include mediaTablet {
    top: rem(65);
    height: calc(100vh - #{rem(65)});

    max-width: calc(100vw);
  }

  @include mediaMobile {
    top: rem(56);

    height: calc(100vh - #{rem(56)});
  }

  & .header-location {
    width: 100%;

    @include mediaTablet {
      display: flex;
    }

    &__text {
      width: 100%;
      max-width: unset;

      color: var(--text-light-primary);
      font: var(--font-body-S);
      text-transform: none;
      white-space: unset;
    }
  }

  & .social-list {
    gap: rem(10);

    @include mediaMobile {
      gap: rem(10);
    }
  }

  &__list {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: rem(24);

    @include mediaTablet {
      padding: rem(30) var(--container-offset) rem(40);
    }

    @include mediaMobile {
      padding: rem(25) var(--container-offset) rem(80);
      gap: rem(20);
    }
  }

  &__item {
    width: 100%;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: rem(10);

    border: 0;

    @include mediaTablet {
      display: flex;
    }

    &::after {
      display: none;
    }
  }

  &__link {
    position: relative;
    margin: 0;

    padding: 0;

    font: var(--font-body-M);
  }

  &__custom {
    --mm-navbar-size: -1px;
    margin: 0;

    padding: rem(110) var(--container-offset);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(20);
    width: 100%;

    @include mediaBigDesktop {
      padding: big(110) var(--container-offset);
    }

    @include mediaLaptop {
      padding: rem(90) var(--container-offset);
    }

    @include mediaTablet {
      padding: rem(40) rem(0) 0;

      border-top: solid 1px var(--stroke-dark-secondary);
    }

    @include mediaMobile {
      padding: rem(20) rem(0) 0;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(15);
  }

  &__close {
    position: absolute;
    top: rem(42);
    right: var(--container-offset);
    z-index: 3;

    width: rem(24);
    height: rem(24);

    @include mediaBigDesktop {
      top: big(42);

      width: big(24);
      height: big(24);
    }

    @include mediaLaptop {
      top: rem(39);

      width: rem(18);
      height: rem(18);
    }

    @include mediaTablet {
      display: none;
    }

    @include hover {
      & .button {
        &__icon {
          fill: var(--accent-light-primary);
        }
      }
    }

    & .button {
      &__icon {
        width: 100%;
        height: 100%;

        fill: var(--text-dark-primary);
      }
    }
  }

  & .mm {
    &-panel {
      &::after {
        display: none;
      }

      &[data-mm-parent] {
        & .mm {
          &-navbar {
            padding: rem(18) var(--container-offset) 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: rem(10);

            border: none;

            @include mediaMobile {
              padding: rem(12) var(--container-offset) 0;
            }

            &__title {
              padding: 0;
              margin: 0;

              display: flex;
              align-items: start;
              justify-content: start;

              color: var(--accent-light-primary);
              font-family: var(--font-primary);
              font-size: rem(12);
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              letter-spacing: 0.6px;
              text-transform: uppercase;
            }

            &__btn {
              top: -1px;
              right: 0;
              bottom: 0;
              left: 0;
              width: rem(16);
              height: rem(16);

              &::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M3.72351 7.05731L6.58551 4.19531L7.52818 5.13798L5.33284 7.33331H11.9995V8.66665H5.33284L7.52818 10.862L6.58551 11.8046L3.72351 8.94265C3.47355 8.69261 3.33313 8.35353 3.33313 7.99998C3.33313 7.64643 3.47355 7.30735 3.72351 7.05731Z' fill='%2391B6B2'/%3E%3C/svg%3E");
                position: unset;
                padding: 0;
                margin: 0;

                width: 100%;
                height: 100%;

                border: none;

                transform: none;
              }
            }
          }

          &-listview {
            // padding: 0 rem(20);

            & .mobile-menu {
              &__item {
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    &-navbar {
      display: none;
      border: none;

      &__title {
        padding: rem(18) var(--container-offset) 0;
        justify-content: start;

        color: var(--accent-dark-primary);
        font: var(--font-body-L);
        font-weight: 500;

        @include mediaMobile {
          padding: rem(12) var(--container-offset) 0;
        }
      }
    }

    &-listitem {
      &__btn {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;

        width: rem(16);
        height: rem(16);

        border: none;
        background-color: transparent;

        &::after {
          top: 0;
          right: 2px;
          bottom: 0;
          left: 0;
          padding: 0;
          margin: auto;
          content: "";

          width: rem(7);
          height: rem(7);

          border-width: 1px;
        }

        &::before {
          @include pseudo(14)
        }
      }
    }
  }

  & .contacts-info {
    background: transparent;

    &__row {
      flex-direction: column;

      @include mediaTablet {
        flex-direction: row;
      }

      @include mediaMobile {
        flex-direction: column;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;

      @include mediaTablet {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      @include mediaMobile {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.mm {
  &-wrapper {
    &__blocker {
      background-color: #000;
    }

    &--opened {
      & .mm {
        &-wrapper {
          &__blocker {
            opacity: 0.1;

            @include mediaTablet {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
